import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import BackgroundPicture from '../components/BackgroundPicture';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const VideoIframe = styled.div`
    position: relative;
    padding-bottom: 53%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    border-radius: 10px;
    margin: 7% 0%;
`;

const IframeVideo = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('Shop');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_SHOP')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_SHOP')}/>
            </Helmet>
            <Layout>
                <BackgroundPicture
                    image={data.background.childImageSharp}
                    alt='Open store sign and store front'
                />
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_STORE_LOCATOR')}
                        cellParagraph={t('DESCRIPTION_STORE_LOCATOR')}
                        pictureRight={data.retail.childImageSharp}
                        altDescription='mobile screen view of map with Intermarché'
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map3',
                                text: t('ICON_SYNCH')
                            },
                            {
                                icon: 'icon-tools',
                                text: t('ICON_UI_UX')
                            },
                            {
                                icon: 'icon-camera',
                                text: t('ICON_THUMBNAIL')
                            },
                            {
                                icon: 'icon-export',
                                text: t('ICON_BAKED_IN_SERVICES')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_2_IN_1')}
                            text={t('DESCRIPTION_2_IN_1')}
                            titleButton={t('BUTTON_LEARN_MORE')}
                            icons={[
                                {
                                    icon: 'icon-location1',
                                    text: t('ICON_GEOLOC')
                                },
                                {
                                    icon: 'icon-shopping-basket',
                                    text: t('ICON_DEALS_AROUND_ME')
                                },
                                {
                                    icon: 'icon-repost',
                                    text: t('ICON_DRIVE_TO_STORE')
                                },
                                {
                                    icon: 'icon-web_asset',
                                    text: t('ICON_A_B_TECH')
                                }

                            ]}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <VideoIframe>
                        <IframeVideo title="youtube"
                            width="101%"
                            height="100%"
                            src="https://www.youtube.com/embed/RRjjNjQSqug"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></IframeVideo>
                    </VideoIframe>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_BEST_IN_CLASS')}
                        cellParagraph={t('DESCRIPTION_BEST_IN_CLASS')}
                        pictureRight={data.indoorNav.childImageSharp}
                        altDescription='mobile with indoor navigation inside market store'
                        titleButton={t('BUTTON_DISCOVER_WEMAP_PLATFORM')}
                        widthPicture='400px'
                        urlFooter='/products/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-tools',
                                text: t('ICON_UX_MOBILE_FIRST')
                            },
                            {
                                icon: 'icon-file-video',
                                text: t('ICON_RICH_EXPERIENCE')
                            },
                            {
                                icon: 'icon-camera',
                                text: t('ICON_AR')
                            },
                            {
                                icon: 'icon-export',
                                text: t('ICON_BAKED_IN_SERVICES_2')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_STORE_MAP')}
                            text={t('DESCRIPTION_STORE_MAP')}
                            titleButton={t('BUTTON_LEARN_MORE')}
                            icons={[
                                {
                                    icon: 'icon-location1',
                                    text: t('ICON_INDOOR')
                                },
                                {
                                    icon: 'icon-shopping-basket',
                                    text: t('ICON_CUSTOMIZED')
                                },
                                {
                                    icon: 'icon-repost',
                                    text: t('ICON_HIGHLIGHTS')
                                },
                                {
                                    icon: 'icon-web_asset',
                                    text: t('ICON_TOOLS')
                                }
                            ]}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with person shopping and navigating with mobile'
                        titleBannerPicture={t('TITLE_BANNER')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/' />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  background: file(relativePath: {eq: "background-image/shop-bg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  banner: file(
    relativePath: {eq: "products/shopbywemap/banner-wemap-retail-shop.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  retail: file(relativePath: {eq: "products/shopbywemap/wemap-retail.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 600, layout: FIXED)
    }
  }
  indoorNav: file(relativePath: {eq: "products/shopbywemap/indoor-wemap-nav.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 500, layout: FIXED)
    }
  }
}
`;
