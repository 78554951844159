import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const CellButton = styled.div`
    text-decoration:none;
    margin:1% 0%;

    @media (min-width: 320px) and (max-width: 1024px){
        margin:7% 0%;
    }
`;

const Button = styled.button`
    border-radius: 50px;
    width: auto !important;
    padding: 1em 1.2em;
    border: none;
    font-size:13px;
    font-family:'Montserrat';
    font-weight:300;
    background-color:#227EE6;
    color:white;
    cursor: pointer;
`;

const SecondaryButton = ({ titleButton }) => (
    <CellButton>
        <Button>{titleButton}</Button>
    </CellButton>
);

SecondaryButton.propTypes = {titleButton: PropTypes.string};

export default React.memo(SecondaryButton);
